<template>
  <div>
    <v-card>
      <button
        type="button"
        class="v-btn v-btn--absolute v-btn--fab v-btn--has-bg v-btn--right v-btn--round v-btn--top theme--light v-size--default primary"
        title="Vložit odkaz"
        @click="open_form=true"
      >
        <span
          class="v-btn__content"
        >
          <span
            aria-hidden="true"
            class="v-icon notranslate theme--light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              role="img"
              aria-hidden="true"
              class="v-icon__svg"
            >
              <path
                d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
              >
              </path>
            </svg>
          </span>
        </span>
      </button>
    </v-card>
    <v-card
      :loading="loading"
      :style="`height:${desktop_h - 160}px;padding:10px;`"
    >
      <div :style="`height:${desktop_h - 230}px;overflow-y: scroll;`">
        <v-card-title v-if="clanky.length < 1">
          <div style="line-height:120px;width:100%;text-align:center;">
            Ještě tady nic není, zkuste štěstí později ;)
          </div>
        </v-card-title>

        <v-card
          v-for="(clanok, index) in clanky"
          :key="clanok._id"
          elevation="2"
          outlined
          style="margin-bottom:10px;"
        >
          <v-card-title>
            <div
              style="width:100%;"
              @mouseover="small_show_btn=index"
              @mouseleave="small_show_btn=-1"
            >
              <v-row>
                <v-col cols="1">
                  <!-- AVATAR -->
                  <div style="text-align:center">
                    <v-avatar
                      size="80px"
                    >
                      <v-img
                        v-if="clanok.userinfo && clanok.userinfo.length>0 && clanok.userinfo[0].foto !== null"
                        :src="`${clanok.userinfo[0].foto}`"
                      ></v-img>
                      <v-img
                        v-else
                        :src="`${!isWoman(clanok.autor[0].prijmeni) ? require('@/assets/images/avatars/1.png') : require('@/assets/images/avatars/2.png')}`"
                      ></v-img>
                    </v-avatar>
                    <div style="font-size:9px;margin-top:8px;text-align:center;">
                      {{ clanok.autor[0].jmeno }} {{ clanok.autor[0].prijmeni }}
                      <!-- {{ clanok.datum | moment("DD.MM.YYYY HH:mm") }} -->
                    </div>
                  </div>
                </v-col>

                <v-col cols="11">
                  <!-- ODKAZY -->
                  <div class="text-xl font-weight-semibold primary--text mb-2">
                    <div style="line-height:30px;padding-left: 18px;">
                      {{ clanok.nazev }}
                    </div>
                    <div style="font-size:13px;text-decoration:none;font-weight:400;padding-left:18px;">
                      <span style="color:#7E7886">{{ clanok.datum | moment("DD.MM.YYYY HH:mm") }}</span>
                      <!-- {{ clanok.autor[0].jmeno }} {{ clanok.autor[0].prijmeni }} -->
                    </div>
                    <div
                      v-show="(small_show_btn==index && $store.getters.getProfile._id === clanok.autor[0]._id) || (small_show_btn==index && $store.getters.getProfile.is_admin)"
                      style="float:right"
                    >
                      <v-fab-transition>
                        <v-btn
                          color="primary"
                          fab
                          x-small
                          style="margin-right:5px"
                          @click="onEditForm(clanok._id)"
                        >
                          <v-icon>{{ mdiPencil }}</v-icon>
                        </v-btn>
                      </v-fab-transition>
                      <v-fab-transition>
                        <v-btn
                          color="error"
                          fab
                          x-small
                          @click="ondeleteForm(clanok._id)"
                        >
                          <v-icon>{{ mdiTrashCan }}</v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </div>
                  </div>

                  <v-card-text>
                    <p v-html="clanok.text"></p>
                  </v-card-text>
                </v-col>
              </v-row>

            </div>
          </v-card-title>

        </v-card>
      </div>
      <!-- STRANKOVANIE -->
      <div
        class="text-center"
        style="width:100%;position:absolute;bottom:0px;padding:10px;"
      >
        <v-pagination
          v-if="clanky.length > 0"
          v-model="fetch_settings.paging.current_page"
          :length="fetch_settings.paging.total_pages"
          :total-visible="10"
          circle
          @input="handlePaging"
        ></v-pagination>
      </div>
    </v-card>

    <!-- 1 - aktuality | 2 - clanky | 3 - potrebuji(odkazy) CZE | 4 - potrebuji(odkazy) SVK -->
    <form-clanky
      :show="open_form"
      :title="`${item_id ? 'Upravit položku' : 'Vložit novú položku'}`"
      placeholder="Zde vložte URL odkaz..."
      :type="typ_zpravy"
      :item_id="item_id"
      :show_send_email="false"
      @close="onCloseForm(...arguments)"
    >
    </form-clanky>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>

    <!-- ZMAZAT DIALOG -->
    <v-dialog
      v-model="open_dialog_zmazat"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h7">
          Upozornění
        </v-card-title>

        <v-card-text>
          Skutečne chcete odstranit položku?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="open_dialog_zmazat = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="deleteForm()"
          >
            Odstranit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiPencil,
  mdiTrashCan,
} from '@mdi/js'
import axios from 'axios'
import latinize from 'latinize'
import SnackBar from '@/components/snackbar/index.vue'
import FormClanky from '@/components/form-clanky/index.vue'
import response from '@/mixins/response'

export default {
  components: {
    'snack-bar': SnackBar,
    'form-clanky': FormClanky,
  },
  mixins: [response],
  data() {
    return {
      item_id: '',
      loading: false,
      mdiChevronUp,
      mdiChevronDown,
      mdiPencil,
      mdiTrashCan,
      desktop_w: 1024,
      desktop_h: 768,
      small_show_btn: -1,
      show: [],
      clanky: [],
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      open_form: false,
      open_dialog_zmazat: false,
      typ_zpravy: undefined,
      fetch_settings: {
        filter: {
          typ_zpravy: 3,
          active: true,
          smazane: false,
        },
        paging: {
          limit: 4,
          skip: 0,
          current_page: 1,
          total_pages: 1,
          total_records: 1,
        },
        sort: {
          datum: -1,
        },
      },
    }
  },
  watch: {
    '$store.state.global.filter_search': {
      handler(_newVal) {
        const filter = this.buildFilter(latinize(_newVal).toLowerCase())
        this.fetchPolozky(filter)
      },
      immediate: true,
      deep: true,
    },
    '$route.name': {
      handler(_new_val) {
        if (_new_val === 'pages-potrebuji-cze' || _new_val === 'pages-potrebuji-svk') {
          this.fetchPolozky()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
    this.fetchPolozky()
  },
  methods: {
    windowResize() {
      this.desktop_w = window.innerWidth
      this.desktop_h = window.innerHeight
    },
    handleTypeMsg() {
      if (this.$route.name === 'pages-potrebuji-cze') {
        this.typ_zpravy = 3
        this.fetch_settings.filter.typ_zpravy = 3
      } else {
        this.typ_zpravy = 4
        this.fetch_settings.filter.typ_zpravy = 4
      }
    },
    handlePaging(p_page) {
      // vypocitaj hodnotu skip a potom zavolaj nove nacitanie dat z DB
      const { limit } = this.fetch_settings.paging
      this.fetch_settings.paging.skip = (limit * p_page) - limit
      this.fetchPolozky()
    },
    buildFilter(_search) {
      const filter = { // default
        typ_zpravy: 3,
        active: true,
        smazane: false,
      }
      if (_search && _search.length > '') {
        const search = { $regex: `${_search}`, $options: 'i' }
        filter.$or = [
          { latin_nazev: search },
          { latin_text: search },
        ]
      }

      return filter
    },
    fetchPolozky(p_filter) {
      this.loading = true
      this.fetch_settings.filter = p_filter || this.fetch_settings.filter
      this.handleTypeMsg()
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/clanky/index`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.clanky = resp.data.db_data
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    showDetail(p_id) {
      if (this.show.includes(p_id)) {
        const index = this.show.indexOf(p_id)
        if (index > -1) {
          this.show.splice(index, 1)
        }
      } else {
        this.show.push(p_id)
      }
    },
    onEditForm(p_id) {
      this.item_id = p_id
      this.open_form = true
    },
    ondeleteForm(p_id) {
      console.log('ondeleteForm', p_id)
      this.item_id = p_id
      this.open_dialog_zmazat = true
    },
    deleteForm() {
      this.open_dialog_zmazat = false
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/clanky/${this.item_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'delete',
      }
      axios(config)
        .then(resp => {
          console.log('ondeleteForm => ', resp)
          this.fetchPolozky()
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    onCloseForm(p_param) {
      this.open_form = false
      this.item_id = undefined
      if (p_param.refresh !== false) {
        this.fetchPolozky()
      }
    },
    isWoman(p_val) {
      /* eslint newline-before-return: "off" */

      if (p_val) {
        if (p_val.slice(p_val.length - 1) === 'á') {
          return true // iba ak je zena
        }
      }
      return false // default
    },
  },
}
</script>

<style>
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
</style>
